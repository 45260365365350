<template>
  <div class="page page__content-wrapper event">
    <div class="page__full-width event-full event-top">
      <div class="page__min-container">
        <div class="text__head">
          Ролл-ап
        </div>
      </div>
      <div class="event__press-wall">
        <div class="page__link-block">
          <a :href="rollup.urlZip"
             @click.prevent="downloadItemZip(rollup)"
             class="btn__circle btn__nav-slider btn-download">
            <img src="~assets/img/icons/icon-ai.svg" alt="AI">
          </a>
        </div>
      </div>
    </div>
    <div class="page__full-width">
      <div class="text__normal">
        Интерьерный баннер «Блэкаут», 440 г/м<sup>2</sup> <br>
        Полноцветная цифровая печать, 1440 точек на дюйм
      </div>
    </div>
    <div class="page__full-width">
      <div class="page__min-container">
        <div class="page__img-wrapper">
          <img src="~assets/img/event-decoration-page/event-1.png" alt="Пресс-волл">
        </div>
      </div>
    </div>
    <div class="page__full-width event-full  event-top">
      <div class="page__min-container">
        <div class="text__head">
          Стенд / Прессволл
        </div>
      </div>
      <div class="event__press-wall">
        <div class="page__link-block">
          <a :href="stand.urlZip"
             @click.prevent="downloadItemZip(stand)"
             class="btn__circle btn__nav-slider btn-download">
            <img src="~assets/img/icons/icon-ai.svg" alt="AI">
          </a>
        </div>
      </div>
    </div>
    <div class="page__full-width">
      <div class="page__min-container">
        <div class="text__normal">
          Наиболее предпочтителен вариант, в котором все выставочные носители печатаются без рубиново-розового
          градиента. Рубиново-розовые световые пятна затем добавляются прямо на выставочной площадке при помощи точечных
          осветительных приборов (spot lights или beam lights). Художественная настройка приборов выполняется
          поставщиком выставочного стенда исходя из дизайна выставочного пространства и может включать в себя
          динамическое движение световых пятен по синим поверхностям.
        </div>
      </div>
    </div>
    <div class="page__full-width">
      <div class="page__min-container presswall-wrapper">
        <div class="page__two-block">
          <div class="page__img-wrapper">
            <img src="~assets/img/event-decoration-page/ev-1.svg" alt="Пресс-волл">
          </div>
        </div>
        <div class="page__two-block">
          <div class="page__img-wrapper">
            <img src="~assets/img/event-decoration-page/ev-2.svg" alt="Пресс-волл">
          </div>
        </div>
      </div>
    </div>
    <div class="page__full-width">
      <div class="page__min-container">
        <div class="text__normal">
          Рекомендуемые цветные фильтры LEE для приборов с температурой 3200K:
        </div>
      </div>
    </div>
    <div class="page__full-width">
      <div class="page__min-container">
        <div class="page__two-block presswall-wrapper event-filter">
          <div class="page__two-block color-filter">
            <div class="page__img-wrapper img-wrap">
              <img src="~assets/img/event-decoration-page/event-4.png" alt="Пресс-волл">
            </div>
            <div class="text__normal blue">
              027 Medium Red
            </div>
            <div class="text__normal">
              Пленочный цветной фильтр
            </div>
          </div>
          <div class="page__two-block dichroic-filter">
            <div class="page__img-wrapper img-wrap">
              <img src="~assets/img/event-decoration-page/event-5.png" alt="Пресс-волл">
            </div>
            <div class="text__normal blue">
              R99 Flame 9
            </div>
            <div class="text__normal">
              Дихроичный фильтр
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page__full-width">
      <div class="page__min-container">
        <div class="text__normal">
          Рекомендуемые значения для LED-приборов:
        </div>
      </div>
    </div>
    <div class="page__full-width">
      <div class="page__min-container">
        <div class="page__two-block presswall-wrapper event-filter">
          <div class="page__two-block press-wall">
            <div class="page__img-wrapper img-wrap">
              <img src="~assets/img/event-decoration-page/event-6.png" alt="Пресс-волл">
            </div>
            <div class="text__normal">
              Код цвета в системе RGBW:
              <span class="text__normal blue">206, 0, 69, 23</span>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="page__full-width event-full print-wall">
      <div class="page__min-container">
        <div class="text__normal blue">
          Напечатанный стенд
        </div>
        <div class="text__normal">
          В запасном, упрощённом варианте рубиново-розовый градиент просто является частью
          дизайн-макета и печатается одновременно с остальными элементами.
        </div>
      </div>
      <div class="page__link-block">
        <a :href="stand.urlZip"
           @click.prevent="downloadItemZip(stand)"
           class="btn__circle btn__nav-slider btn-download">
          <img src="~assets/img/icons/icon-ai.svg" alt="AI">
        </a>
      </div>
    </div>
    <div class="page__full-width">
      <div class="page__min-container">
        <div class="page__img-wrapper">
          <img src="~assets/img/event-decoration-page/event-3.png" alt="Пресс-волл">
        </div>
      </div>
    </div>

    <div class="page__download-block ">
      <a class="main-btn_download"
         :href="all.urlZip"
         @click.prevent="downloadItemZip(all)"
      >
        <svg style="margin-right: 8px;" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="24" cy="24" r="16" stroke="white" stroke-linecap="round" stroke-dasharray="0.1 4"/>
          <path
              d="M23.6464 26.3536C23.8417 26.5488 24.1583 26.5488 24.3536 26.3536L27.5355 23.1716C27.7308 22.9763 27.7308 22.6597 27.5355 22.4645C27.3403 22.2692 27.0237 22.2692 26.8284 22.4645L24 25.2929L21.1716 22.4645C20.9763 22.2692 20.6597 22.2692 20.4645 22.4645C20.2692 22.6597 20.2692 22.9763 20.4645 23.1716L23.6464 26.3536ZM24.5 18L24.5 17.5L23.5 17.5L23.5 18L24.5 18ZM24.5 26L24.5 18L23.5 18L23.5 26L24.5 26Z"
              fill="white"/>
          <path d="M31 27V29C31 29.5523 30.5523 30 30 30H18C17.4477 30 17 29.5523 17 29V27" stroke="white"
                stroke-linecap="square"/>
        </svg>
        Скачать все макеты
      </a>
    </div>

    <div class="page-nav__tablet">
      <router-link class="next-page__link" to="/brand/souvenir-products">
        <img src="~assets/img/icons/row-grey-small.svg" alt="prev">
        Сувенирная продукция
      </router-link>
      <router-link class="next-page__link" to="/brand/transport">
        Транспорт
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
    <div class="next-page">
      <router-link class="next-page__link" to="/brand/transport">
        Транспорт
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
  </div>
</template>
<script>
import Axios from "axios";

export default {
  data() {
    return {
      all: {
        urlZip: '../dowload-file/Brand--Office/event-decoration/Events.zip',
        label: "Events"
      },
      rollup: {
        urlZip: '../dowload-file/Brand--Office/event-decoration/GDP-Rollup.zip',
        label: "GDP-Rollup"
      },
      stand: {
        urlZip: '../dowload-file/Brand--Office/event-decoration/GDP-Stand.zip',
        label: "GDP-Stand"
      }
    }
  },
  methods: {
    downloadItemZip({urlZip, label}) {
      Axios.get(urlZip, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/zip'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = label
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/page-block";
@import "src/assets/style/text-style";
@import "src/assets/style/buttons";

.page {
  margin-bottom: 192px;

  .page__full-width {

    &:nth-child(2) {
      .text__normal {
        margin-top: -10px;
      }
    }

    &:nth-child(3) {
      .page__min-container {
        margin-bottom: 0;

        .page__img-wrapper {
          margin-bottom: 44px;
        }
      }
    }

    &:nth-child(5) {
      .text__normal {
        margin-bottom: 0;
        max-width: 690px;
        margin-top: -6px;
      }
    }

    &:nth-child(6) {
      .page__min-container {
        margin-bottom: 44px;
      }
    }

    &:nth-child(7) {
      .page__min-container {
        margin-bottom: 32px;

        .text__normal {
          margin-bottom: 0;
        }
      }
    }

    &:nth-child(8) {
      .page__min-container {
        margin-bottom: 16px;

        .page__two-block {
          &.dichroic-filter {
            .text__normal {
              margin-left: -2px;
            }
          }

          .page__img-wrapper {
            width: 184px;
            height: 184px;

            img {
              height: 100%;
              width: 100%;
            }
          }

          .text__normal {
            &.blue {
              margin-bottom: 0;
              color: #000033;
            }
          }
        }
      }
    }

    &:nth-child(9) {
      .page__min-container {
        margin-bottom: 16px;
      }
    }

    &:nth-child(10) {
      .page__min-container {
        margin-bottom: 92px;

        .page__two-block {
          .page__img-wrapper {
            width: 188.7px;
            height: 188.7px;

            img {
              height: 100%;
              width: 100%;
            }
          }

          .text__normal {
            &.blue {
              color: #000033;
            }
          }
        }
      }
    }

    &:nth-child(11) {
      .page__min-container {
        margin-bottom: 0;

        .text__normal {
          max-width: 80%;

          &.blue {
            color: #000033;
            line-height: 24px;
            margin-bottom: 16.4px;
          }
        }
      }

      .page__link-block {
        margin-top: -30px;
        margin-left: 20px;
      }
    }
  }

  .main-btn_download {
    letter-spacing: 0.85px;
    font-weight: 700;
    font-family: 'formular', serif;
  }
}

sup {
  font-size: 10px;
}

.event {
  &__press-wall {
    display: flex;
    position: relative;
    align-items: center;

    &-title {
      position: absolute;
      top: 0;
      z-index: 1;
    }

    &-img {
      max-width: 832px;
      position: relative;

      img {
        position: relative;
        margin-top: -11%;
      }
    }

    .page__link-block {
      display: flex;
      position: relative;
      top: 0;
      margin-left: 20px;
    }
  }

  &__rollup {
    display: flex;
    position: relative;
    align-items: center;
    margin-top: 130px;

    &-title {
      position: absolute;
      top: 0;
      z-index: 1;
    }

    &-img {
      max-width: 832px;
      position: relative;

      img {
        position: relative;
        margin-top: -1%;
      }
    }
  }

  &__top {
    align-items: flex-start;
  }
}

.page__link-block {
  display: flex;
}

.event-full {
  display: flex;
}

.presswall-wrapper .page__img-wrapper.img-wrap {
  margin-bottom: 32px;
}

.presswall-wrapper {
  display: flex;
  justify-content: space-between;

  .page__img-wrapper {
    margin-bottom: 0;
  }
}

.event-top {
  display: flex;
}

.text__head {
  margin-bottom: 0;
  font-weight: 500;
}

.event-top .page__min-container {
  padding-top: 8px;
  margin: 0;
  border-top: 1px solid #60608040;
}

.print-wall {
  .text__bold {
    margin-bottom: 32px;
  }
}

.page__two-block {
  width: calc(50% - 16px);
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 208px;
  }
}

@media screen and (max-width: 1246px) {
  .page__download-block {
    display: none;
  }
}

@media screen and (max-width: 1216px) {
  .pages-wrap {
    .container {
      .page {
        .page__full-width {
          &:nth-child(2) {
            .text__normal {
              margin-top: 10px;
            }
          }

          &:nth-child(5) {
            .page__min-container {
              .text__normal {
                margin-top: 10px;
              }
            }
          }
          &:nth-child(11) {
            .page__min-container {
              .text__normal {
                max-width: initial;
              }
            }
          }
        }
      }
    }
  }
  .page__link-block {
    display: none;
  }
  .event__press-wall .page__link-block {
    display: none;
  }
  .event__press-wall-img {
    margin-bottom: 0;

    img {
      margin-top: auto;
    }
  }
  .event__rollup-title {
    position: relative;
  }
  .btn__gradient-main.btn__left-icon {
    display: none;
  }
  .event__rollup {
    margin-top: 64px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.text__normal {
  margin-bottom: 32px;
}

@media screen and (max-width: 768px) {
  .event-filter {
    width: 100%;
  }
  .page__full-width {
    width: 100%;

    .page__min-container {
      .page__two-block {
        &.color-filter,
        &.press-wall,
        &.dichroic-filter {
          width: calc(50% - 6px);

          .page__img-wrapper {
            width: 100% !important;
          }
        }
      }
    }
  }
  .page__two-block {
    width: calc(50% - 6px);
  }
}

@media screen and (max-width: 420px) {
  .event-filter {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .page__two-block {
      width: 100%;
    }
  }
}
</style>
